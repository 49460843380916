/* Tailwind core */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* CSS variables */
@layer base {
  html[data-theme="prime"] .prime-only {
    display: flex;
  }

  html:not([data-theme="prime"]) .prime-only {
    display: none;
  }

  html:not([data-theme="prime"]) .not-prime {
    display: flex;
  }

  html[data-theme="prime"] .not-prime {
    display: none;
  }

  html[data-theme="prime"] {
    --logo: url("/assets/prime/logo.svg");
    /*
     * PRIMITIVES
     */

    /* Gray */
    --gray-900: 215 23% 15%;
    --gray-800: 240 6% 31%;
    --gray-700: 240 16% 33%;
    --gray-600: 211 9% 45%;
    --gray-500: 215 16% 73%;
    --gray-400: 222 18% 89%;
    --gray-300: 224 38% 94%;
    --gray-200: 0 0% 95%;
    --gray-100: 300 14% 99%;
    --gray-0: 0 0% 100%;

    /* Brand */
    --primary: 238 82% 64%;
    --primary-light: 231 100% 95%;
    --primary-dark: 242 47% 51%;
    --primary-inverted: 237 100% 80%;
    --primary-inverted-dark: 244 58% 68%;
    --primary-transparent: 238 82% 64%;
    --secondary: 230 27% 40%;
    --secondary-light: 230 21% 46%;
    --secondary-dark: 237 15% 26%;

    /* Accents */
    --highlight: 165 100% 60%;
    --highlight-alt: 58 92% 67%;
    --positive: 166 79% 42%;
    --positive-inverted: 165 41% 87%;
    --negative: 356 89% 62%;
    --negative-inverted: 356 89% 96%;
    --uncertain: 40 100% 68%;
    --uncertain-inverted: 38 100% 97%;

    /*
     * END PRIMITIVES (BELOW ARE DEPRECATED)
     */

    /* Brand */
    --brand: 237 82% 64%;
    --brand-50: 237 82% 64% 50%;
    --brand-secondary: 241 47% 50%;
    --brand-tertiary: 241 76% 72%;
    /* --highlight: 166 100% 64%; Duplicated (Name conflict) */
    --affirmative: 166 78% 41%;
    --affirmative-secondary: 165 58% 94%;
    /* --uncertain: 39 100% 68%; Duplicated (Name conflict) */
    --uncertain-secondary: 37 100% 96%;
    /* --negative: 345 78% 55%; Duplicated (Name conflict) */
    --negative-secondary: 346 81% 95%;

    /* Text */
    --text-100: 0 0% 100%;
    --text-100-40: 0 0% 100% 40%;
    --text-200: 230 100% 94%;
    --text-300: 244 100% 86%;
    --text-400: 215 16% 73%;
    --text-400-40: 215 16% 73% 40%;
    --text-600: 211 9% 45%;
    --text-700: 230 26% 39%;
    --text-800: 240 6% 31%;
    --text-800-50: 240 6% 31% 50%;
    --text-900: 215 22% 14%;

    /* Background */
    --background-100: 0 0% 100%;
    --background-100-10: 0 0% 100% 10%;
    --background-200: 300 14% 98%;
    --background-300: 231 100% 98%;
    --background-400: 0 0% 95%;
    --background-500: 230 27% 55%;
    --background-600: 230 26% 39%;
    --background-800: 240 6% 31%;
    --background-900: 240 9% 25%;
    --background-900-33: 240 9% 25% 33%;

    /* Border */
    --border-300: 223 37% 94%;
    --border-500: 0 0% 84%;

    /* Values */
    --radius: 0.5rem;
    --ring: 216 34% 17%;

    /* Shadcn/ui */
    /* https://ui.shadcn.com/docs/installation/manual */
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    /* https://ui.shadcn.com/docs/components/sidebar */
    --sidebar-background: 193, 37%, 27%;
    --sidebar-foreground: 0 0% 100%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 165 100% 87%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-highlight-inverted: 193 37% 27%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  html[data-theme="zelis"] {
    /*
     * PRIMITIVES
     */

    /* Gray */
    --gray-900: 215 23% 15%;
    --gray-800: 240 6% 31%;
    --gray-700: 240 16% 33%;
    --gray-600: 211 9% 45%;
    --gray-500: 215 16% 73%;
    --gray-400: 222 18% 89%;
    --gray-300: 224 38% 94%;
    --gray-200: 0 0% 95%;
    --gray-100: 300 14% 99%;
    --gray-0: 0 0% 100%;

    /* Brand */
    --primary: 244 94% 53%;
    --primary-light: 231 100% 95%;
    --primary-dark: 242 47% 51%;
    --primary-inverted: 268 54.88% 32.16%;
    --primary-inverted-dark: 244 58% 68%;
    --primary-transparent: 238 82% 64%;
    --secondary: 230 27% 40%;
    --secondary-light: 230 21% 46%;
    --secondary-dark: 237 15% 26%;

    /* Accents */
    --highlight: 165 100% 60%;
    --highlight-alt: 58 92% 67%;
    --positive: 166 79% 42%;
    --positive-inverted: 165 41% 87%;
    --negative: 356 89% 62%;
    --negative-inverted: 356 89% 96%;
    --uncertain: 40 100% 68%;
    --uncertain-inverted: 38 100% 97%;

    /*
     * END PRIMITIVES (BELOW ARE DEPRECATED)
     */

    /* Brand */
    --brand: 237 82% 64%;
    --brand-50: 237 82% 64% 50%;
    --brand-secondary: 241 47% 50%;
    --brand-tertiary: 241 76% 72%;
    /* --highlight: 166 100% 64%; Duplicated (Name conflict) */
    --affirmative: 166 78% 41%;
    --affirmative-secondary: 165 58% 94%;
    /* --uncertain: 39 100% 68%; Duplicated (Name conflict) */
    --uncertain-secondary: 37 100% 96%;
    /* --negative: 345 78% 55%; Duplicated (Name conflict) */
    --negative-secondary: 346 81% 95%;

    /* Text */
    --text-100: 0 0% 100%;
    --text-100-40: 0 0% 100% 40%;
    --text-200: 230 100% 94%;
    --text-300: 244 100% 86%;
    --text-400: 215 16% 73%;
    --text-400-40: 215 16% 73% 40%;
    --text-600: 211 9% 45%;
    --text-700: 230 26% 39%;
    --text-800: 240 6% 31%;
    --text-800-50: 240 6% 31% 50%;
    --text-900: 215 22% 14%;

    /* Background */
    --background-100: 0 0% 100%;
    --background-100-10: 0 0% 100% 10%;
    --background-200: 300 14% 98%;
    --background-300: 231 100% 98%;
    --background-400: 0 0% 95%;
    --background-500: 230 27% 55%;
    --background-600: 230 26% 39%;
    --background-800: 240 6% 31%;
    --background-900: 240 9% 25%;
    --background-900-33: 240 9% 25% 33%;

    /* Border */
    --border-300: 223 37% 94%;
    --border-500: 0 0% 84%;

    /* Values */
    --radius: 0.5rem;
    --ring: 216 34% 17%;

    /* Shadcn/ui */
    /* https://ui.shadcn.com/docs/installation/manual */
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    /* https://ui.shadcn.com/docs/components/sidebar */
    --sidebar-background: 268 100% 15%;
    --sidebar-foreground: 0 0% 100%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 38 100% 59%;
    --sidebar-accent-foreground: 247 100% 98%;
    --sidebar-highlight-inverted: 244 94% 53%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;

    /* Zelis: Simplifying styles and list of colors to just increment by one when encountering a new one */
    --logo: url("/assets/zelis/logo.svg");
    --embed-bg-default: url("/assets/zelis/embed-inline-background-default.svg");
    --embed-bg-start: url("/assets/zelis/embed-inline-background-start.svg");

    --color-1: #4f257f;
    --color-2: #727edd;
    --color-3: #ee1d2e;
    --color-4: #ffb32c;
    --color-5: #2516f8;
    --color-6: #3bc884;
    --color-7: #23004b;
    --color-8: #f7f6ff;

    --color-9: #320fff;
    --color-10: #e4dff7;
    --color-11: #3e1d7e1a;
    --color-12: #392182;
    --color-13: #b0a2ca;
    --color-14: #331868;
    --color-15: #3318681a;
    --color-16: #ffffff;
    --color-17: #ada6b8;
    --color-18: #dee1e8;
  }

  .partner-logo {
    aspect-ratio: 68 / 13;
    background-image: var(--logo);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .embed-background-default {
    background-image: var(--embed-bg-default);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .embed-background-start {
    background-image: var(--embed-bg-start);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background font-normal text-foreground;
  }
}
